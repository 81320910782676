<template>
  <teleport to="body">
    <transition name="fade" appear>
      <div class="modal" @mousedown.self="enableClickout" @click.self="clickOut" :style="{ zIndex: windowVM.style.zIndex}" v-if="show" v-bind="$attrs">
        <div class="modal-content" :style="{ width: width, height: height}" >
          <div class="modal-header">
            <h3 class="modal-heading" v-html="heading"></h3>
            <slot name="header"></slot>
            <div class="modal-close-button" @click.self="close()" v-if="hasCloseButton">&times;</div>
          </div>
          <div ref="modalWindowContent" class="modal-content-inner">
            <input type="hidden"/>
            <slot :closeWindow="close"></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
/**
 * Modales Fenster.
   Nutzung:
   
   let isWindowVisible = shalloRef(false);
   ...
   
   <ModalWindow v-model:show="isWindowVisible" heading="Text im Header" :hasCloseButton="true">
     Hier kommt der Inhalt hin
   </ModalWindow>      
   
   ---------
   Man oeffnet/schliesst das Fenster mit isWindowVisible.value = true/false;   
   ---------
   
   Man kann *in* dem Fenster auch das Schliessen des Fensters aktivieren. Das ist dann leider ein wenig sperriger:
      
   <ModalWindow v-model:show="isWindowVisible" heading="Text im Header" :hasCloseButton="true">
      <template v-slot:default="{ closeWindow }">
         ... Hier kommt der Inhalt hin!
         <StatuswechselMessages :se="se" v-on:close="closeWindow"/>
         <button v-on:click="$emit('close')">Fenster zu machen!!</button>
      </template>    
   </ModalWindow>      
   Wenn man statt einem "button" direkt im ModalWindow eine Komponente aufruft, muss diese "close" emitten. 
   ---------
   Property <ModalWindow :closeOnClickOut=true"/> schliesst das Fenster, wenn man neben das Fenster klickt.   
 */
 

import { ref,shallowReactive, toRef, watchEffect, onMounted, onUnmounted, nextTick, provide, watch } from "vue";
import { drawZValue} from "./bringToTop"

// Dies ist der Stack der modalen Fenter. Das zuletzt geöffnete Fenster ist TopOfStack
// Offene Aufgabe: Dieser Stack müsste auch für DraggableWindow benutzt werden.
const visibleModalWindowStack = shallowReactive([]);


window.document.addEventListener("keyup", (event) => {
  if (visibleModalWindowStack.length == 0) return;
  if (event.keyCode === 27) {
    visibleModalWindowStack[visibleModalWindowStack.length - 1].windowVM.value.close();
  }
  // do something
});

//Body-Scrolling verhindern, wenn ein fenster sichtbar ist
watchEffect(() => {
  if(visibleModalWindowStack.find(b => b.showRef.value) && !document.body.classList.contains("noscroll")){
    document.body.classList.add("noscroll");
  }
  else{
    document.body.classList.remove("noscroll");
  }
});

export default {
  props:{
    'show': Boolean,
    'heading': [String, ref],
    'hasCloseButton': {
      type: Boolean,
      default: false
    },
    'closeOnClickOut': {
      type: Boolean,
      default: false
    },
    width: { type: String},
    height: { type: String}
  },
  inheritAttrs: false,
  emits:["update:show", "open", "close", "internalClose"],
  setup: function(props, context){
    let close = (e) => {
      context.emit("internalClose");
      context.emit("update:show", false)
    };
    let showRef = toRef(props, "show");
    let windowVM  = ref({
      style: {},
      close
    });

    provide("inModalWindow", true);

    const modalWindowContent = ref();
    const thisHandle = {
      showRef: showRef,
      windowVM: windowVM
    };
    visibleModalWindowStack.push(thisHandle);
    const unregisterAtWindowStack = () => {
      const indx = visibleModalWindowStack.indexOf(thisHandle);
      if (indx>=0) visibleModalWindowStack.splice(indx,1);
    }
    let enableClickout = false;
    
    function clickOut(){
      if(props.closeOnClickOut && enableClickout)
        close();
      enableClickout = false;
    }

    function focus() {
      nextTick(() => {
        if(modalWindowContent.value)
          getKeyboardFocusableElements(modalWindowContent.value)[0]?.focus()
      })
    }

    onMounted(()=>{
      // bringToTop(theWindow);
      windowVM.value.style.zIndex = drawZValue();
    })

    function onShowRefChange() {
      if (showRef.value) {
        windowVM.value.style.zIndex = drawZValue();
        focus();
        if (!visibleModalWindowStack.find(x => x == thisHandle)) {
          visibleModalWindowStack.push(thisHandle);
        }
      }
      else {
        unregisterAtWindowStack();
      }
    }

    onShowRefChange(); // Startwert
    watch(showRef, onShowRefChange);

    watch(showRef,() => {
      context.emit(showRef.value ? "open" : "close");
    });

    onUnmounted(()=>{
      unregisterAtWindowStack();
    });

    /**
 * Gets keyboard-focusable elements within a specified element
 * @param {HTMLElement} [element=document] element
 * @returns {Array}
 */
function getKeyboardFocusableElements (element = document) {
  return Array.from(
    element.querySelectorAll(
      'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    ))
    .filter(
    el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
  )
}
    
    return {
      modalWindowContent,
      close,
      clickOut,
      windowVM,
      enableClickout(){
        enableClickout = true;
      },
      
    }
  }
}
</script>

<style lang="scss">
 .modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content{
  background-color: #fefefe;
  border: 2px solid #666;
  min-width: 300px;
  max-width: 80vw;
  max-height: 80vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* padding: 0.5em 1em; */
  border-radius: 1em;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
                0 2px 2px rgba(0,0,0,0.12), 
                0 4px 4px rgba(0,0,0,0.12);
}

.modal-content-inner{
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 5px 10px 10px 10px;
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 10px 10px 5px 10px;
}
.modal-heading {
  width: 100%;
}

/* The Close Button */
.modal-close-button {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}

.modal-close-button:hover,
.modal-close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-heading{
  display: inline-block;
}

.noscroll{
  overflow:hidden;
}

.modal.big {
  .modal-content{
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
  }
}

</style>